import React, {useState, useEffect, useRef} from 'react';
//import isMobile from '../../../general/isMobile';
import HoveringBackButton from '../../../general/HoveringBackButton';
import './GOL.css';

var isMobile = window.innerWidth <= 768;
export default function GOL(props){

    //console.log("GOL")
    const canvasRef = useRef(null)
    
    var gridHeight = isMobile ? window.innerWidth - 30 : 400;
    var gridWidth = isMobile ? window.innerWidth - 30 : 400;
    var theGrid = createArray(gridWidth);
    var mirrorGrid = createArray(gridWidth);
    //og
    //var colors = ["#db8059", "#FF0000", "#c40b40", "#d6b10e"]
    //waterey variant
    var colors = ["#A9DDD6", "#7A8B99", "#91ADC2", "#9BA0BC", "#C1B8C8"]

    //whats really cool is i was gonna use coolors.co to generate a waterfally pallette
    //and in one go it pulled this up. Its fate
    //https://coolors.co/a9ddd6-7a8b99-91adc2-9ba0bc-c1b8c8


    fillRandom(); //create the starting state for the grid by filling it with random cells

    //tick(); //call main loop

    //functions
    function tick(ctx) { //main loop
        //console.time("loop");
        drawGrid(ctx);
        updateGrid(ctx);
        ctx.fillStyle = colors[Math.floor(Math.random() * 4)];
        
        console.timeEnd("loop");
        //requestAnimationFrame(tick);
    }

    function createArray(rows) { //creates a 2 dimensional array of required height
        var arr = [];
        for (var i = 0; i < rows; i++) {
            arr[i] = [];
        }
        return arr;
    }

    function fillRandom() { //fill the grid randomly
        for (var j = 100; j < gridHeight - 100; j++) { //iterate through rows
            for (var k = 100; k < gridWidth - 100; k++) { //iterate through columns
                theGrid[j][k] = Math.round(Math.random());
            }
        }
    }

    function drawGrid(ctx) { //draw the contents of the grid onto a canvas
        var liveCount = 0;
        ctx.clearRect(0, 0, gridHeight, gridWidth); //this should clear the canvas ahead of each redraw
        for (var j = 1; j < gridHeight; j++) { //iterate through rows
            for (var k = 1; k < gridWidth; k++) { //iterate through columns
                if (theGrid[j][k] === 1) {
                    ctx.fillRect(j, k, 1, 1);
                    liveCount++;

                }
            }
        }
        //console.log(liveCount/100);
    }

    function updateGrid(ctx) { //perform one iteration of grid update

        for (var j = 1; j < gridHeight - 1; j++) { //iterate through rows
            for (var k = 1; k < gridWidth - 1; k++) { //iterate through columns
                var totalCells = 0;
                //add up the total values for the surrounding cells
                totalCells += theGrid[j - 1][k - 1]; //top left
                totalCells += theGrid[j - 1][k]; //top center
                totalCells += theGrid[j - 1][k + 1]; //top right

                totalCells += theGrid[j][k - 1]; //middle left
                totalCells += theGrid[j][k + 1]; //middle right

                totalCells += theGrid[j + 1][k - 1]; //bottom left
                totalCells += theGrid[j + 1][k]; //bottom center
                totalCells += theGrid[j + 1][k + 1]; //bottom right

                //apply the rules to each cell
                switch (totalCells) {
                    case 2:
                        mirrorGrid[j][k] = theGrid[j][k];

                        break;
                    case 3:
                        mirrorGrid[j][k] = 0; //live
            mirrorGrid[j-1][k+1] = 1;
            mirrorGrid[j-1][k+2] = 1;
                        break;
            case 5:
            //mirroGrid[j][k] = 2;
                    default:
                        mirrorGrid[j][k] = 0; //
            mirrorGrid[j+1][k-1] = 0;

            mirrorGrid[j][k-1] = 0;

                }
            }
        }

        //mirror edges to create wraparound effect

        for (var l = 1; l < gridHeight - 1; l++) { //iterate through rows
            //top and bottom
            mirrorGrid[l][0] = mirrorGrid[l][gridHeight - 3];
            mirrorGrid[l][gridHeight - 2] = mirrorGrid[l][1];
            //left and right
            mirrorGrid[0][l] = mirrorGrid[gridHeight - 3][l];
            mirrorGrid[gridHeight - 2][l] = mirrorGrid[1][l];

        }


        //swap grids
        var temp = theGrid;
        theGrid = mirrorGrid;
        mirrorGrid = temp;
    }


    useEffect(() => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        context.fillStyle = "#FF0000";
        let animationFrameId
        if(context){
            const render = () => {
                //console.log('rendered')
                tick(context);
                animationFrameId = window.requestAnimationFrame(render)

            }
            render();
        }

        return () => {
            window.cancelAnimationFrame(animationFrameId)
        }
    },[tick]);


    var size = 400;
    if(isMobile){
        size =  window.innerWidth - 30 ;
    }
    return(
            <canvas id = "myCanvas" 
                height={size}
                width={size} 
                ref={canvasRef}
                style ={{border : "1px solid #fff"}}>
            </canvas>
    );
}
/*
var gridHeight = 400;
var gridWidth = 400;
var theGrid = createArray(gridWidth);
var mirrorGrid = createArray(gridWidth);
var c = document.getElementById("myCanvas");
var ctx = c.getContext("2d");
ctx.fillStyle = "#FF0000";
var colors = ["#db8059", "#FF0000", "#c40b40", "#d6b10e"]
fillRandom(); //create the starting state for the grid by filling it with random cells

tick(); //call main loop

//functions
function tick() { //main loop
    console.time("loop");
    drawGrid();
    updateGrid();
  ctx.fillStyle = colors[Math.floor(Math.random() * 4)];
    console.timeEnd("loop");
    requestAnimationFrame(tick);
}

function createArray(rows) { //creates a 2 dimensional array of required height
    var arr = [];
    for (var i = 0; i < rows; i++) {
        arr[i] = [];
    }
    return arr;
}

function fillRandom() { //fill the grid randomly
    for (var j = 100; j < gridHeight - 100; j++) { //iterate through rows
        for (var k = 100; k < gridWidth - 100; k++) { //iterate through columns
            theGrid[j][k] = Math.round(Math.random());
        }
    }
}

function drawGrid() { //draw the contents of the grid onto a canvas
  var liveCount = 0;
    ctx.clearRect(0, 0, gridHeight, gridWidth); //this should clear the canvas ahead of each redraw
    for (var j = 1; j < gridHeight; j++) { //iterate through rows
        for (var k = 1; k < gridWidth; k++) { //iterate through columns
            if (theGrid[j][k] === 1) {
                ctx.fillRect(j, k, 1, 1);
                  liveCount++;

            }
        }
    }
      console.log(liveCount/100);
}

function updateGrid() { //perform one iteration of grid update

    for (var j = 1; j < gridHeight - 1; j++) { //iterate through rows
        for (var k = 1; k < gridWidth - 1; k++) { //iterate through columns
            var totalCells = 0;
            //add up the total values for the surrounding cells
            totalCells += theGrid[j - 1][k - 1]; //top left
            totalCells += theGrid[j - 1][k]; //top center
            totalCells += theGrid[j - 1][k + 1]; //top right

            totalCells += theGrid[j][k - 1]; //middle left
            totalCells += theGrid[j][k + 1]; //middle right

            totalCells += theGrid[j + 1][k - 1]; //bottom left
            totalCells += theGrid[j + 1][k]; //bottom center
            totalCells += theGrid[j + 1][k + 1]; //bottom right

            //apply the rules to each cell
            switch (totalCells) {
                case 2:
                    mirrorGrid[j][k] = theGrid[j][k];

                    break;
                case 3:
                    mirrorGrid[j][k] = 0; //live
          mirrorGrid[j-1][k+1] = 1;
          mirrorGrid[j-1][k+2] = 1;
                    break;
        case 5:
          //mirroGrid[j][k] = 2;
                default:
                    mirrorGrid[j][k] = 0; //
          mirrorGrid[j+1][k-1] = 0;

          mirrorGrid[j][k-1] = 0;

            }
        }
    }

    //mirror edges to create wraparound effect

    for (var l = 1; l < gridHeight - 1; l++) { //iterate through rows
        //top and bottom
        mirrorGrid[l][0] = mirrorGrid[l][gridHeight - 3];
        mirrorGrid[l][gridHeight - 2] = mirrorGrid[l][1];
        //left and right
        mirrorGrid[0][l] = mirrorGrid[gridHeight - 3][l];
        mirrorGrid[gridHeight - 2][l] = mirrorGrid[1][l];

    }


    //swap grids
    var temp = theGrid;
    theGrid = mirrorGrid;
    mirrorGrid = temp;
}
*/