import React, {useEffect, useState} from "react";
import isMobile from "../../general/isMobile";
export default function LandingPage({title}){
    var mobile = isMobile();
    return(
        <div>
            <h1 className={mobile ? "mobile-title": "title"}>
                {title}
            </h1>
        </div>
    )
}