import React, { useState } from "react";
import SiteContents from "./components/Contents/SiteContents";
import DitherWrapper from "./components/LandingPage/DitherWrapper";
import LandingCanvas from "./components/LandingPage/LandingCanvas";
import Exhibition from "./components/Projects/Exhibition";
import GOL from "./components/Projects/GOL/GOL";
import Mandala from "./components/Projects/Mandala/Mandala";


function App() {
  const [stage, setStage] = useState(0);
  
  const linkTo = (url) => {
    window.open(url, '_blank')
  }
  return(
    <div className="page-wraper">
      {stage == 0 &&
      (<>
        <DitherWrapper/>
        <LandingCanvas 
          maxDiam={Math.max(window.innerHeight, window.innerWidth)}
          moveAlong={() => {setStage(1);}}/>
      </>)
      }
      {stage ==1 &&
      (
        <SiteContents
          goBackToLanding={() => setStage(0)}
          goToMandala={() => {setStage(2)}}
          goToGOL={() => {setStage(3)}}
          linkTo={linkTo}/>
      )}
      {stage == 2 &&
        <Exhibition title="Mandala" description ="Js generative canvas art to create mandala-like structures rendered real time in browser. Completely random and unique." goBackToSiteContents={() => setStage(1)}>
          <Mandala />
        </Exhibition>
      }   
      {stage == 3 && 
      <Exhibition title="Waterfalls" 
      description =" Celular Automata. Rendere real time in browser via canvas. Fooled with conways game of life variables and ended up creating an interesting waterfall structure. It flashes like crazy and dies after a while. Still, I thought it was kind of pretty." 
      goBackToSiteContents={() => setStage(1)}>
        <GOL
          goBackToSiteContents={() => setStage(1)}
        />
      </Exhibition>
      }
    </div>
  );
}

export default App;
