import React, { useRef, useEffect, useState } from 'react'
//import isMobile from '../../../general/isMobile';
import HoveringBackButton from '../../../general/HoveringBackButton';

var isMobile = window.innerWidth <= 768;
export default function Mandala(props){
    const canvasRef = useRef(null)

    useEffect(() => {
        //var size = Math.min(window.innerWidth, window.innerHeight);
        let size = isMobile ? window.innerWidth - 30: 400;
        var canvas = canvasRef.current;
        var ctx = canvas.getContext("2d");

        //set size
        canvas.setAttribute("width", size);

        //set Stuff
        ctx.fillStyle = "#FF0000";
        
        drawNutsShit();
        
        //use to draw polygons. keep outer and inner radius same
        //otherwise can use to draw variety of stars
        //drawStar(size/2, size/2, 90000, 80, 40);


        //random color
        function getRandColor()
        {
            var color = Math.floor(Math.random() * Math.pow(256, 3)).toString(16);
            while(color.length < 6) {
                color = "0" + color;
            }
            return "#" + color;
        }

        //randomNumber
        function getRandNumber(min, max){

        return Math.floor(Math.random() * max) + min;
        }


        //draw circle
        function drawCirlce(){
        ctx.beginPath();
        //          x,    y,  r, start angle,   end angle
        //ctx.arc(200, 200, 200, 0          , 2 * Math.PI);
        ctx.arc(size/2, size/2, size/2, 0          , 2 * Math.PI);
        //0 and 2* math.pi make a cirlce
        ctx.stroke();
        }

        //draw a triangle
        function drawTriangle(){
        //top to bottom left
        ctx.moveTo(size/2, 0);
        ctx.lineTo(0, size);
        ctx.stroke();

        //bottom left to bottom right
        ctx.lineTo(size,size);
        ctx.stroke();

        //back to top center;
        ctx.lineTo(size/2, 0);
        ctx.stroke();
        }

        //draw a start
        function drawStarMe(){

        //top to bottom left
        ctx.moveTo(size/2, 0);
        ctx.lineTo(0, size);
        ctx.stroke();

        //bottom left to mid right
        ctx.lineTo(size, size*(1/3));
        ctx.stroke();

        //mid right to mid left
        ctx.lineTo(0, size* (1/3));
        ctx.stroke();

        //mid right to bottom right
        ctx.lineTo(size,size);
        ctx.stroke();

        //back to top center;
        ctx.lineTo(size/2, 0);
        ctx.stroke();
        }

        //some star code i found on stack overflow
        function drawStar(cx,cy,spikes,outerRadius,innerRadius){
            var rot=Math.PI/2*3;
            var x=cx;
            var y=cy;
            var step=Math.PI/spikes;

            ctx.beginPath();
            ctx.moveTo(cx,cy-outerRadius)
            for(var i=0;i<spikes;i++){
            x=cx+Math.cos(rot)*outerRadius;
            y=cy+Math.sin(rot)*outerRadius;
            ctx.lineTo(x,y)
            rot+=step

            x=cx+Math.cos(rot)*innerRadius;
            y=cy+Math.sin(rot)*innerRadius;
            ctx.lineTo(x,y)
            rot+=step
            }
            ctx.lineTo(cx,cy-outerRadius);
            ctx.closePath();
            ctx.lineWidth=5;
            ctx.strokeStyle='blue';
            ctx.stroke();
            ctx.fillStyle='skyblue';
            ctx.fill();
        }

        //altered said code to allow color
        function drawStar(cx,cy,spikes,outerRadius,innerRadius, color1, color2){
            var rot=Math.PI/2*3;
            var x=cx;
            var y=cy;
            var step=Math.PI/spikes;

            ctx.beginPath();
            ctx.moveTo(cx,cy-outerRadius)
            for(var i=0;i<spikes;i++){
            x=cx+Math.cos(rot)*outerRadius;
            y=cy+Math.sin(rot)*outerRadius;
            ctx.lineTo(x,y)
            rot+=step

            x=cx+Math.cos(rot)*innerRadius;
            y=cy+Math.sin(rot)*innerRadius;
            ctx.lineTo(x,y)
            rot+=step
            }
            ctx.lineTo(cx,cy-outerRadius);
            ctx.closePath();
            ctx.lineWidth=5;
            ctx.strokeStyle=color1;
            ctx.stroke();
            ctx.fillStyle=color2;
            ctx.fill();
        }

        //this looks wild
        function drawNutsShit(){
            let startNm = 400;

            while (startNm >= 0){

            //console.log(startNm);

            let col1 = getRandColor();
            let col2 = getRandColor();

            let rand = getRandNumber(5,20);

            drawStar(size/2, size/2, 10000, startNm, startNm-rand, col1, col2);
            startNm = startNm - rand;

            }
        }

    }, [canvasRef])



    console.log('is mobile');
    console.log(isMobile);
    var size = 400;
    if(isMobile){
        size =  window.innerWidth - 30 ;
    }
    return(
            <canvas id="myCanvas"
                height={size}
                width={size} 
                ref={canvasRef} 
                style ={{border : "1px solid #fff"}}>

            </canvas>
            
    )
}