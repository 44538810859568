import React, {useState, useEffect, useRef} from 'react';
import isMobile from '../../general/isMobile';
import HoveringBackButton from '../../general/HoveringBackButton';


export default function Exhibition(props){
    
    return(
        <div id={isMobile ? "mobile-site-contents" : "site-contents"}>
            <div>
                <h1 className={isMobile ? "mobile-title" : "title"}>
                    {props.title}
                </h1>
            </div>
            {props.children}
            <div className="about">
                {props.description}
            </div>
            
            <HoveringBackButton func={props.goBackToSiteContents}/>
        </div>
    )
}