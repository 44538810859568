import React,{useEffect, useState} from 'react';
import HoveringBackButton from '../../general/HoveringBackButton';
import isMobile from '../../general/isMobile';

export default function SiteContents(props){

    return(
        <div id={isMobile ? "mobile-site-contents" : "site-contents"}>
            <div>
                <h1 className={isMobile ? "mobile-title" : "title"}>
                    Cameron W Thomas
                </h1>
            </div>
            <div className="about">
                Web and software developer. Also a musician.
            </div>
            <div>
                <div className="section">
                    <div className="section-header">Projects</div>
                    <div className="section-link"> <span  onClick={() => props.linkTo("https://fsr.live")}> frozen section radio (site developer) </span> </div>
                    <div className="section-link" > <span onClick={props.goToMandala}> mandala </span></div>
                    <div className="section-link" > <span onClick={props.goToGOL}> cellular automata (epileptics beware. seriously.) </span></div>
                    <div className="section-link"> <span  onClick={() => props.linkTo("http://www.weatherdotcomsucks.org")}> www.weatherdotcomsucks.org </span> </div>
                    
                </div>
                <div className="section">
                    <div className="section-header">Music</div>
                    <div className="section-link"> <span  onClick={() => props.linkTo("https://leisuresport.bandcamp.com/album/title-card")}> Leisure Sport (Bass) </span> </div>
                    <div className="section-link" > <span  onClick={() => props.linkTo("https://oblonggato.bandcamp.com/")}> Obling Gato </span></div>
                
                </div>
            </div>
            <HoveringBackButton func={props.goBackToLanding}/>
        </div>
    );
}