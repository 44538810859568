import React, {useEffect, useState} from "react";
import isMobile from "../../general/isMobile";
import LandingPage from "./LandingPage";
import "../../dither.scss"

export default function DitherWrapper(){
    return(
        <div className="dither-wrapper">
            <LandingPage title="Cameron W Thomas"/>
        </div>
    )
}