import React, { useRef, useEffect, useState } from 'react'


export default function HoveringBackButton(props){

    return(
        <div className="hovering-back-button">
            <i className="fas fa-caret-left carrot" onClick={props.func}></i>
        </div>
    )
}