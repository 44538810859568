import React, { useRef, useEffect, useState } from 'react'

const Canvas = props => {
  
    //const { draw, ...rest } = props
    const canvasRef = useRef(null)
    const [isDrawing, setIsDrawing] = useState(false);
    const [xy, setXY] = useState({x:-123, y:-123});

    const handleStart = (event, type) => {
        //var x= 0;
        //var y= 0;

        event.preventDefault();
        if(type == "mouse"){
            //x = event.clientX;
            //y = event.clientY;
            setXY({x: event.clientX, y: event.clientY});
        }else{
            //x = event.touches[0].clientX;
            //y = event.touches[0].clientY;
            setXY({x: event.touches[0].clientX, y: event.touches[0].clientY});
        }
        //setRadius(1);
        //setIsDrawing(true); 
        //draw();
        //startDrawing();

    }   
    const handleEnd = (event, type) => {
        //setIsDrawing(false);
        setXY({x:-123, y:-123})
    }

    /*
    const draw = (ctx, frameCount) => {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
        ctx.fillStyle = '#000000'
        ctx.beginPath()
        ctx.arc(xy.x, xy.y, 20*Math.sin(frameCount*0.05)**2, 0, 2*Math.PI)
        ctx.fill()
    }
    */

    var draw = (context, radius) =>{
        
        if(radius  <  props.maxDiam){// + 100 / 2){
            //context.clearRect(0,0, canvas.width, canvas.height)
            context.beginPath();
            context.arc(xy.x, xy.y, radius, 0, 2 * Math.PI, false); 
            context.fillStyle = '#000';
            context.fill();
            context.lineWidth = 5;
            context.strokeStyle = '#000';
            context.stroke();
        }else if(radius >= props.maxDiam){// + 100/ 2){
            //alert('moveAlong');
            //context.clearRect(0,0, canvas.width, canvas.height)
            props.moveAlong();
        }
    }   

    var remove = (context, radius) =>{
        if(radius >0){
                
            context.clearRect(0,0, window.innerWidth, window.innerHeight)
            context.beginPath();
            context.arc(xy.x, xy.y, radius, 0, 2 * Math.PI, false); 
            context.fillStyle = '#000';
            context.fill();
            context.lineWidth = 5;
            context.strokeStyle = '#000';
            context.stroke();
        }else if(radius <= 0){
            context.clearRect(0,0, window.innerWidth, window.innerHeight)
        }
    }

    useEffect(() => {
        if(xy.x != -123 && xy.y !=-123){
            const canvas = canvasRef.current
            const context = canvas.getContext('2d')
            let frameCount = 0
            let animationFrameId
            
            const render = () => {
                    frameCount+=5
                    draw(context, frameCount)
                    animationFrameId = window.requestAnimationFrame(render)
                
            }
            render()
            
            return () => {
                window.cancelAnimationFrame(animationFrameId)
            }
        }
    }, [draw]);
    
  return (
    <canvas  
            id="lp-canvas"
            height={window.innerHeight}
            width={window.innerWidth} 
            ref={canvasRef}
            onTouchStart={e => {handleStart(e,"touch")}}
            onTouchEnd={e => {handleEnd(e,"touch")}}
            onMouseDown={e => {handleStart(e,"mouse")}}
            onMouseUp={e => {handleEnd(e,"mouse")}}
        />
  )
}

export default Canvas